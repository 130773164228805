export const settingClassTableConf = {
	"1": [
		{
			label: '商品名称',
			prop: 'name'
		},
		{
			label: '类目颜色',
			prop: '2'
		},
		// {
		// 	label: '负责人',
		// 	prop: '3'
		// },
		{
			label: '佣金比例%',
			prop: 'rate'
		},
		{
			label: '服务费',
			prop: 'service_fee'
		},
		{
			label: '保证金',
			prop: 'deposit'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	"2": [
		{
			label: '模板名称',
			prop: '1'
		},
		{
			label: '模板分类',
			prop: '2'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	"3": [
		{
			label: '类目',
			prop: '1'
		},
		{
			label: '对接人',
			prop: '2'
		},
	]
}

export const settingClassFormConf = [
	{
		label: '分类名称',
		prop: 'title',
		type: 'input'
	},
	{
		label: '类目颜色',
		prop: '2',
		type: 'color'
	},
	// {
	// 	label: '负责人',
	// 	prop: '3',
	// 	type: 'select',
	// 	options: []
	// },
	{
		label: '保证金',
		prop: 'deposit',
		type: 'input'
	},
	{
		label: '佣金百分比',
		prop: 'rate',
		type: 'input',
		append: '%'
	},
	{
		label: '服务费',
		prop: 'service',
		type: 'input'
	},
]

export const typeTableConf = [
	{
		label: '分类名称',
		prop: '1'
	},
	{
		label: '保证金（元）',
		prop: '2'
	},
	{
		label: '佣金比例',
		prop: '3'
	},
	{
		label: '服务费（元）',
		prop: '4'
	},
	{
		label: '操作',
		prop: 'operation'
	},
]