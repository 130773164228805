<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="类目管理" name="1"></el-tab-pane>
<!--			<el-tab-pane label="类目价格" name="2"></el-tab-pane>-->
<!--			<el-tab-pane label="类目对接人" name="3"></el-tab-pane>-->
		</el-tabs>
		<el-button type="primary" v-if="activeName === '1'" @click="add">新增</el-button>
		<el-button type="primary" v-if="activeName === '2'" @click="$refs.dialogTypeRef.handleOpen(); title = '新增';">添加活动模板</el-button>
		<div v-if="activeName === '3'">
			<el-form label-width="auto" inline>
				<el-form-item label="商品类目">
					<el-select>
						<el-option value="1" label="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="对接人">
					<el-select>
						<el-option value="1" label="1"></el-option>
					</el-select>
				</el-form-item>
				<el-button type="primary">添加</el-button>
			</el-form>
		</div>
		<e-table @query-Table-data="handleCurrentChange" ref="tables" :tableCols="settingClassTableConfItem" :dataOrigin="dataOrigin">
			<template v-if="activeName === '1'" #2>
				<div class="item brs_2"></div>
			</template>
			<template v-if="activeName === '2'" #2>
				<el-tag>美妆护肤</el-tag>
			</template>
			<template v-if="activeName === '3'" #2>
				<el-tag closable>诗洁招商1</el-tag>
			</template>
			<template #operation="scope">
				<el-button v-if="activeName === '1' || activeName === '2'" @click="edit(scope.rowData.id)">编辑</el-button>
				<el-button v-if="activeName === '2'" type="danger">删除</el-button>
			</template>
		</e-table>
		<e-dialog :title="title + '分类'" width="500px" ref="dialogRef" v-on:confirm="confirm">
			<e-form ref="formRef" :formConfList="settingClassFormConf" />
		</e-dialog>
		<e-dialog :title="title + '模板'" width="1000px" ref="dialogTypeRef">
			<div class="flex_r f_ai_c mb30">
				<span class="mr10">模板名称</span>
				<el-input class="w_200"></el-input>
			</div>
			<el-button type="primary">添加分类</el-button>
			<e-table :tableCols="typeTableConf" :dataOrigin="dataOrigin">
				<template #1>
					<el-select></el-select>
					<el-option value="1" label="1"></el-option>
				</template>
				<template #2>
					<el-input></el-input>
				</template>
				<template #3>
					<el-input>
						<div slot="append">%</div>
					</el-input>
				</template>
				<template #4>
					<el-input></el-input>
				</template>
				<template #operation>
					<el-button type="danger">删除</el-button>
				</template>
			</e-table>
		</e-dialog>
	</div>
</template>

<script>
import {settingClassTableConf, settingClassFormConf, typeTableConf} from '@data/system/settingClass'
export default {
	name: 'SettingClass',
	data() {
		return {
			title: '新增',
			activeName: '1',
			settingClassTableConf,
			settingClassFormConf,
			typeTableConf,
			dataOrigin: {
				data: [
					{}
				]
			},
			form: {},
			currentPage: 1,
			pageSize: 10,
			total: 0,
			id: ''
		}
	},
	computed: {
		settingClassTableConfItem() {
			return this.settingClassTableConf[this.activeName]
		},
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetLists()
		},
		add(){
			this.id = ''
			this.form = {}
			this.$refs.dialogRef.handleOpen();
			this.title = '新增';
		},
		edit(id){
			let that = this
			that.form = {}
			this.id = id
			this.$refs.dialogRef.handleOpen();
			this.title = '编辑';
			let loading = this.$loadings.service({
				'text': "请求中",
				background: 'rgba(28,28,28,0.7)',
			})
			that.get('?server=live.set.class.item.get',{id: id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data)
				that.$refs.formRef.formData = response.data
				// that.$refs.formRef.formData.title = response.data.name
				// that.$refs.formRef.formData.rate = response.data.rate
				// that.$refs.formRef.formData.service = response.data.service_fee
				// that.$refs.formRef.formData.deposit = response.data.deposit

				loading.close()
			}).catch(function (error) {
				loading.close()
				that.$message.error('请求超时');
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		handleClick() {
		},
		confirm(){
			let that = this
			let formData = this.$refs.formRef.formData
			this.post('?server=live.goods.category.handle.post',{
				id: this.id,
				title:formData.title,
				rate:formData.rate,
				service:formData.service,
				deposit:formData.deposit,}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.tables.loading = true
				that.GetLists()
				that.$refs.dialogRef.handleClose()
				that.$refs.formRef.formData = {}
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetLists(){
			let that = this
			this.dataOrigin.data = []
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=live.goods.category.lists.get',{types:this.activeName,
				page:this.currentPage,
				query:this.form.name,
				cat:this.form.cat,
				baotime:this.form.baotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.data.length
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.$refs.tables.loading = false
				// that.dataOrigin.data = response.data.data
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
		});
		this.GetLists()
	}
}
</script>

<style lang="scss" scoped>
.item {
	width: 60px;
	height: 20px;
	background-color: salmon;
	margin: 0 auto;
}
</style>